/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    hdmi: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 7a.5.5 0 000 1h11a.5.5 0 000-1z"/><path pid="1" d="M1 5a1 1 0 00-1 1v3a1 1 0 001 1h.293l.707.707a1 1 0 00.707.293h10.586a1 1 0 00.707-.293l.707-.707H15a1 1 0 001-1V6a1 1 0 00-1-1zm0 1h14v3h-.293a1 1 0 00-.707.293l-.707.707H2.707L2 9.293A1 1 0 001.293 9H1z"/>',
    },
});
